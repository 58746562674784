export default class UserInitialsConfig {
    public big?: boolean = false;
    public showFullName?: boolean = true;
    public isCentered?: boolean = true;
    public boldText?: boolean = false;
    public subtitle?: string | null;
    public border?: boolean = false;
    public showTooltip?: boolean = true;

    /**
     * The status badge is a small badge that describes the status of the user initals component
     */
    public statusBadgeData?: { backgroundColor: string, icon: string };
}
