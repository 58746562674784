

























import {Component, Prop, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import ContactPerson from '@/models/ContactPerson';
import UserInitialsConfig from '@/misc/UserInitialsConfig';

@Component({})
export default class UserInitialsComponent extends Vue {

  /**
   * The user to be displayed
   */
  @Prop()
  public user!: User | ContactPerson;

  /**
   * An optional config for the representation
   */
  @Prop({default: () => new UserInitialsConfig()})
  public config!: Partial<UserInitialsConfig>;

  public coloravatar = '';
  /**
   *  Returns the user initials
   */
  public get initials(): string {
    if (this.user.firstName && this.user.lastName) {
      return `${this.user.firstName[0]}${this.user.lastName[0]}`.toUpperCase();
    }
    return '';
  }

  /**
   * Get user color (depends on the full name of the user)
   */
  public get userColor(): string {
    this.coloravatar = `employee_color_${this.digitSum(`${this.user.firstName}${this.user.lastName}`)}_text--text`;
    return `employee_color_${this.digitSum(`${this.user.firstName}${this.user.lastName}`)}`;
  }
  /**
   * Convert string to integer value to show a 'random' color for the initials
   * @param target The name of the user
   * @param maxValue The maxValue of the color that should be displayed. Default is 10
   */
  private digitSum(target: string, maxValue: number = 10) {
    if (target.length === 0) {
      return 0;
    }

    // Get the lowerCase letters of the full name
    const letters = target.toLowerCase().split('');
    // Get the letters of the name. Numbers and special characters are ignored
    const array = letters.filter((char) => char.match(/[a-z]/) !== null);
    // Get the char codes
    const charCodes = array.map((char) => char.charCodeAt(0));
    // Concatenating the charCodes
    let charCodeString = charCodes.join('');

    let sum;
    // do while the sum is grater than the maxValue + 1
    do {
      // Get the digits of the charCodeString with .split() and parse the numbers to int for sure
      const splitted = charCodeString.split('').map((num) => parseInt(num));
      // Reduce the array by sum every number. In the next cycle this will happen to the reduced array and so on
      sum = splitted.reduce((a, b) => a + b, 0);

      if (maxValue < 9) {
        sum = parseInt(sum.toString());
      }

      charCodeString = sum.toFixed(0);
    } while (sum > maxValue + 1);

    return sum === 1 ? sum : sum - 1; // -1 cause sum is always > 0
  }
}
