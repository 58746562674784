



























import {Vue, Prop, Component, VModel} from 'vue-property-decorator';
import {TabItem} from '@/interfaces/TabItem';

@Component
export default class RJTabs extends Vue {

  @VModel({default: 0})
  private tabsModel!: number;

  @Prop({default: () => []})
  private tabItems!: TabItem[];

  private get getAvailableTabs() {
    return this.tabItems.filter((item) => item.available !== false);
  }

  @Prop({default: ''})
  private height!: string;

}
